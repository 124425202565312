<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="interval"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-chart
      :id="'exercise-calories-chart-component' + Id"
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      :tooltip="IsDashboard ? {} : tooltip"
      background="transparent"
      width="100%"
      :title="IsDashboard ? $t('module.charts.' + Chart.Name) : ''"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :height="IsDashboard ? '185px' : '90%'"
      isResponsive="true"
    >
      <e-series-collection>
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData"
          type="Column"
          :xName="Chart.DataProperties.chart.x"
          :yName="Chart.DataProperties.chart.y"
          :marker="IsDashboard ? {} : marker"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData"
          type="Column"
          :xName="Chart.DataProperties.diary.x"
          :yName="Chart.DataProperties.diary.y"
          :marker="marker"
        ></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</template>

<script>
import Vue from "vue";
import { DiaryLogic } from "../../../../../Logic/Backend/diary";
import moment from "moment";
import {
  ChartPlugin,
  ColumnSeries,
  DateTime,
  Tooltip,
  StripLine,
} from "@syncfusion/ej2-vue-charts";
import { ChartUiLogic } from "@/Logic/Ui/chart-ui";
import { ChartBackendLogic } from "../../../../../Logic/Backend/chart";
import IntervalManager from "../Components/IntervalManager.vue";

Vue.use(ChartPlugin);

export default {
  components: { IntervalManager },
  name: "ExerciseCalories",
  data() {
    return {
      chartData: [],
      currentDate: moment(),
      currentDateText: null,
      interval: null,
      chartType: null,
      //Chart paraméterek
      xAxis: {
        title: this.IsDashboard ? null : this.$t("base.calendar.date"),
        valueType: "DateTime",
        interval: this.IsDashboard ? 5 : null,
        intervalType: "Days",
        labelFormat: "MM-dd",
        labelIntersectAction: "Rotate45",
      },
      yAxis: {
        title: this.IsDashboard ? null : this.$t("module.charts.kcal"),
        valueType: "Double",
        labelFormat: "n0",
        minimum: 0,
      },
      palettes: ["var(--eh-secondary-5)"],
      marker: {
        visible: true,
      },
      tooltip: {
        enable: true,
        header: this.$t("module.charts." + this.Chart.Name),
        format: "${point.x} : <b>${point.y}</b> " + this.Chart.DisplayUnit,
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        },
      },
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
    };
  },
  props: {
    Interval: String,
    ChartType: String,
    PatientId: Number,
    IsDashboard: Boolean,
    Chart: Object,
    Id: [String, Number],
  },
  provide: {
    chart: [ColumnSeries, DateTime, Tooltip, StripLine],
  },
  methods: {
    //intervallum beállítás
    setInterval() {
      //intervallum objektum generálás adott dátum, intervallum alapján
      const interval = ChartUiLogic.IntervalManager.setInterval(
        this.interval,
        this.xAxis,
        this.currentDate
      );
      //kapott intervallum object tárolás
      this.currentDate = interval.currentDate;
      this.currentDateText = interval.currentDateString;
      this.xAxis = interval.xAxis;
    },
    //intervallum csere
    async intervalChanged(currentDate, interval) {
      //kiválasztott dátum tárolás
      this.currentDate = currentDate;
      //kiválasztott intervallum tárolás
      this.interval = interval;
      //intevallum napi?
      if (interval == this.$enums.ChartInterval.Day.Value) {
        //igen,chart típus napló tárolás
        this.chartType = this.$enums.DiagramType.diary;
      } else {
        //nem, kapott chart típus tárolás
        this.chartType = this.ChartType;
      }
      //adatok betöltése
      await this.getData();
      //intervallum beállítása
      this.setInterval();
    },
    //adatok kérés
    async getData() {
      //chart típus chart?
      if (this.chartType == this.$enums.DiagramType.chart) {
        //chart adatok kérés
        await this.getChartData();
        //chart típus napló?
      } else if (this.chartType == this.$enums.DiagramType.diary) {
        //napló adatok kérés
        await this.getDiaryData();
      }
    },
    //chart adatok kérés
    async getChartData() {
      //kérés válasz
      var responseChartData = null;
      //van beteg id?
      if (this.PatientId) {
        //igen, beteg chart adatok kérés
        responseChartData = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        //nincs, saját chart datok kérés
        responseChartData = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //kapott chart adatok tárolása
        this.chartData = responseChartData;
        //üres mezők feltötlése
        this.fillEmptyDays(this.Chart.DataProperties.chart.x, {
          [this.Chart.DataProperties.chart.y]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //napló adatok kérés
    async getDiaryData() {
      //kérés válasz
      var responseChartData = null;
      //van beteg id?
      if (this.PatientId) {
        //igen, beteg napló adatok kérés
        responseChartData = await DiaryLogic.getPatientDiary(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToDiary()
        );
      } else {
        //nem, saját napló adatok kérés
        responseChartData = await DiaryLogic.getDiary(
          this.Chart.DatabaseName,
          this.getIntervalParamsToDiary()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //kapott napló adaotk tárolása
        this.chartData = responseChartData;
        //üres mezők feltöltése
        this.fillEmptyDays(this.Chart.DataProperties.diary.x, {
          [this.Chart.DataProperties.chart.y]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //napló intervallum paraméter megadás
    getIntervalParamsToDiary() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToDiary(
        this.interval,
        this.currentDate
      );
    },
    //chart intervallum paraméter megadás
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    //üres mezők feltötlése
    fillEmptyDays(datetimeName, value) {
      var lastElement = this.chartData[this.chartData.length - 1];
      if (lastElement) {
        this.chartData.push({
          [datetimeName]: moment(lastElement[datetimeName])
            .add(
              1,
              this.interval == this.$enums.ChartInterval.Day.Value
                ? "hours"
                : "days"
            )
            .format(),
          ...value,
        });
      }
    },
  },
  //betöltéskor egyszer legut
  async mounted() {
    //kapott chat típus tárolás
    this.chartType = this.ChartType;
    //adott intervallum tárolása
    this.interval = this.Interval;
    //intevallum beállítása
    this.setInterval();
    //adatok betöltése
    await this.getData();
  },
};
</script>

<style scoped>
@import '../style.css';
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--eh-secondary-5);
  font-size: 23px;
}
</style>
